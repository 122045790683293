import { Sites } from "@index";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
// import { loadNavigation } from 'providers/actions/loadNavigation'
import { parsePageType } from "providers/actions/parsePageType";
import { fetchApi } from "providers/actions/util";
import React from "react";
import { getSiteData } from "../utils/site";
import { debugTools } from "../utils/debugTools";
import { loadNavigation } from "../utils/loadNavigation";
import {
  AutoRefreshContext,
  AutoRefreshProvider,
} from "providers/AutoRefreshProvider/AutoRefreshProvider";

// SSR Providers
const ServerSidePropsProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-ssrprops' */
      "providers/ServerSideProps"
    ),
  { ssr: true }
);
const PianoInitProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/Piano/PianoInit"
    ),
  { ssr: false }
);
const SeoProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-seo' */
      "providers/Seo"
    ),
  { ssr: true }
);

// Client Providers
const PagesProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-pages' */
      "providers/Pages"
    ),
  { ssr: false }
);
const LayoutProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-pwa-providers' */
      "providers/Layout"
    ),
  { ssr: false }
);
const FilterProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/Filter"
    ),
  { ssr: false }
);
const FilterDataProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/FilterData"
    ),
  { ssr: false }
);
const SlotsProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-slots' */
      "providers/Slots"
    ),
  { ssr: false }
);
const RenderSlots = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-components-base_slots' */
      "components/Base/Base.Slots"
    ),
  { ssr: false }
);
const SessionProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-page' */
      "providers/Session"
    ),
  { ssr: false }
);
const ScriptsProvider = dynamic(
  () =>
    import(
      /* webpackChunkName: 'module-providers-layout' */
      "providers/Scripts"
    )
);

const PageHome = ({ serverSideProps }) => {
  return (
    <ServerSidePropsProvider serverSideData={serverSideProps}>
      {/*<SessionProvider>*/}
      <SeoProvider>
        <AutoRefreshProvider>
          <ScriptsProvider>
            <PagesProvider>
              <SlotsProvider>
                <LayoutProvider>
                  <FilterProvider>
                    <FilterDataProvider>
                      <PianoInitProvider>
                        <RenderSlots />
                      </PianoInitProvider>
                    </FilterDataProvider>
                  </FilterProvider>
                </LayoutProvider>
              </SlotsProvider>
            </PagesProvider>
          </ScriptsProvider>
        </AutoRefreshProvider>
      </SeoProvider>
      {/*</SessionProvider>*/}
    </ServerSidePropsProvider>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const api_url = process.env.NEXT_PUBLIC_WEBAPP_API_SITE_ADDRESS;
  const site_slug = process.env.NEXT_PUBLIC_WEBAPP_CLIENT_SLUG;
  // const site_ext_id = Sites.find((s) => s.slug === site_slug)?.name || ''
  const site_ext_id = getSiteData(site_slug).name;

  const slug = context?.query?.slug || [];
  debugTools.log("[DEBUG] (pages/index) slug in getServerSideProps: ", slug);

  const req_host = context?.req?.headers?.host;
  const req_url: any = context?.req?.url;
  const req_secure_domain = `https://${req_host}`;
  const page_type = parsePageType(slug);

  debugTools.log(
    `[DEBUG] (pages/index) fetchApi: ${api_url}/siteApi/siteData/${site_ext_id}`
  );
  let reqstart = new Date(Date.now()).getTime();
  const siteData = await fetchApi(`${api_url}/siteApi/siteData/${site_ext_id}`);
  debugTools.log(
    "[DEBUG] (pages/index) fetchApi.endRequest: ",
    new Date(Date.now()).getTime() - reqstart
  );

  let loja: string;

  switch (process.env.NEXT_PUBLIC_PAYWALL_PRODUCT) {
    case "OPOPULAR":
      loja = "POPSTORE";
      break;
    case "JORNAL_DO_TOCANTINS":
      loja = "JTOSTORE";
      break;
    case "JORNAL_DAQUI_GO":
      loja = "DAQUI GO STORE";
      break;
    case "JORNAL_DAQUI_TO":
      loja = "DAQUI TO STORE";
      break;
    default:
      loja = "POPSTORE";
      break;
  }
  let statusPaywall;
  try {
    statusPaywall = await fetchApi(
      `${process.env.NEXT_PUBLIC_WEBAPP_PAYWALL_ADDRESS}/config/${loja}/status-paywall`
    );
  } catch (error) {
    console.log(error);
  }

  if (statusPaywall == false) {
    siteData.siteData.pageConfig.featureRadioSelectPaywall = "DESATIVADO";
  }

  const { menuActions, menuSide, menuTop } = siteData?.navigation || {};
  const navigationDataParsed = loadNavigation(
    [menuActions, menuSide, menuTop],
    [siteData?.navigationData],
    siteData?.siteData?.pathSegment
  );

  return {
    props: {
      serverSideProps: {
        article: [],
        domain: req_secure_domain,
        navigation: navigationDataParsed,
        page: {},
        pageSlots: {},
        pageType: page_type,
        session: {},
        site: siteData.siteData,
        siteSlots: siteData.siteSlots,
        siteSlug: site_slug,
      },
    },
  };
};

export default PageHome;
