import { createContext, useCallback, useEffect, useState } from "react";

export interface AutoRefreshContextProps {
  isAutoRefreshEnabled: boolean;
  enableAutoRefresh: () => void;
  disableAutoRefresh: () => void;
}

export const AutoRefreshContext = createContext<AutoRefreshContextProps | undefined>(
  undefined
);

export const AutoRefreshProvider = ({ children }) => {
  const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] =
    useState<boolean>(true);

  const enableAutoRefresh = useCallback(() => setIsAutoRefreshEnabled(true), []);
  const disableAutoRefresh = useCallback(() => setIsAutoRefreshEnabled(false), []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const scheduleRefresh = () => {
      if (isAutoRefreshEnabled) {
        timeoutId = setTimeout(() => {
          window.location.reload();
          scheduleRefresh(); // Re-schedule after refresh
        }, 15 * 60 * 1000);
      }
    };

    scheduleRefresh();

    return () => {
      if (timeoutId) clearInterval(timeoutId);
    };
  }, [isAutoRefreshEnabled]);

  return (
    <AutoRefreshContext.Provider
      value={{ isAutoRefreshEnabled, enableAutoRefresh, disableAutoRefresh }}
    >
      {children}
    </AutoRefreshContext.Provider>
  );
};
